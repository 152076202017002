import { defineStore } from "pinia";
import { ref } from "vue";
import { useCoinStore } from "./coin";
import { useRoute, useRouter } from "vue-router";
import { trackEvent } from "../matomo/tracking";

const LOCAL_STORAGE_TOKEN_KEY = "gamers-heaven-auth-token";

export const useAuthStore = defineStore("auth", () => {
  const isLoggedIn = ref(false);
  const isLoading = ref(false);
  const user = ref({});
  const token = ref(null);

  const coinStore = useCoinStore();

  const router = useRouter();
  const route = useRoute();

  async function logout() {
    trackEvent("auth-logout", user.value?.email);

    isLoggedIn.value = false;
    token.value = null;
    user.value = {};
    router.push({ name: "start" });

    window.localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
  }

  async function checkToken() {
    isLoading.value = true;
    const tokenFromLocalStorage = window.localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

    if (!tokenFromLocalStorage) {
      isLoading.value = false;
      return;
    }

    token.value = tokenFromLocalStorage;
    await fetchUser();
    await coinStore.fetchCoins();

    if (isLoggedIn.value) {
      if(route.name === 'start'){
        router.push({ name: "home" });
      }
    }

    isLoading.value = false;
  }

  async function login(username, password) {
    const response = await fetch("/api/login_check", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ username, password }),
    });

    if (!response.ok) {
      trackEvent("auth-login-error", username);

      return false;
    }

    const json = await response.json();

    if (json.token) {
      isLoggedIn.value = true;
      window.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, json.token);
      token.value = json.token;

      await fetchUser();
      await coinStore.fetchCoins();

      trackEvent("auth-login-success", user.value?.email);

      router.push({ name: "home" });
      return true;
    }

    trackEvent("auth-login-error", username);

    return false;
  }

  async function register(userData) {
    const response = await fetch("/api/gamersheaven/auth/register", {
      method: "POST",
      body: JSON.stringify(userData),
      credentials: "include",
    });

    trackEvent("auth-register", userData?.email);

    return await response.json();
  }

  async function resetPassword(email) {
    const response = await fetch(`/api/gamersheaven/reset-password?email=${encodeURIComponent(email)}`, {
      method: "POST",
    });

    return await response.json();
  }

  async function updateUser(userData) {
    const response = await fetchAuthenticated("/api/gamersheaven/u/update-profile", {
      method: "POST",
      body: JSON.stringify(userData),
      credentials: "include"
    });

    const json = await response.json();

    if(json.success){
      user.value = json.data.user;
    }

    return json;
  }

  async function claimGoodie() {
    user.value.goodieClaimed = true;

    await fetchAuthenticated("/api/gamersheaven/u/claim-goodie", {
      method: "POST",
    });

    trackEvent("goodie-claim", user.value?.email);
  }

  async function fetchUser() {
    const response = await fetch("/api/gamersheaven/u/user", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.value,
      },
    });

    if (response.status === 401) {
      logout();
    }

    const json = await response.json();

    if (json.success) {
      user.value = json.data;
      isLoggedIn.value = true;
    }
  }

  async function fetchAuthenticated(route, options = {}) {
    if (!options.headers) options.headers = {};

    if (isLoggedIn.value) {
      options.headers.Authorization = "Bearer " + token.value;
    }

    return await fetch(route, options);
  }

  return {
    logout,
    checkToken,
    resetPassword,
    login,
    register,
    fetchAuthenticated,
    isLoggedIn,
    isLoading,
    user,
    token,
    updateUser,
    claimGoodie
  };
});
